import FileContract from '@/core/support/files/contracts/FileContract';
import {randomString} from '@/core/helpers/utils/StringUtils';
import Base64FileConverter from '@/core/support/files/services/Base64FileConverter';
import FileConverterService from '@/core/support/files/FileConverterService';
import ExternalUrlFileConverter from '@/core/support/files/services/ExternalUrlFileConverter';
import FileUploadService from '@/core/support/files/FileUploadService';
import FileSaver from 'file-saver';

export default class FileFactory {
  /**
   * @info    File factory
   *
   * @update  Lukas Laskowski 10.07.2019
   */

  /**
   * Unique file token
   */
  public token!: string;

  /**
   * Data
   */
  public id?: number | undefined;
  public mime?: string | undefined;
  public displayName!: string;
  public filename!: string;
  public uuid?: string | undefined;
  public url?: string | undefined;
  public filehash?: string | undefined;

  /**
   * File content as "dataUrl"
   */
  public dataUrl: string = '';

  /**
   * Uploader service
   */
  public uploader: FileUploadService | undefined = undefined;

  /**
   * File error status
   */
  public error = false;

  /**
   * All data loading status
   */
  private dataLoading = true;

  /**
   * Converter service for other data type
   */
  private converter: FileConverterService | undefined = undefined;

  /**
   * Data
   */
  private file: File | undefined;

  /**
   * Set data
   * @param data
   * @param file
   */
  constructor(data: FileContract | null = null, file: File | null = null) {
    this.token = randomString(12);

    if (data !== null) {
      this.syncData(data);
    }
    if (file !== null) {
      this.syncFile(file);
    }
  }

  /**
   * Prepare FileContract data for model
   */
  public prepareUpdate(): FileContract {
    const data: FileContract = {};
    if (this.id) {
      data.id = this.id;
    }
    if (this.uuid) {
      data.uuid = this.uuid;
    }
    return data;
  }

  /**
   * Handle upload
   */
  public async upload() {
    if (!this.uploader) {
      return;
    }
    await this.uploader.upload().then((response) => {
      this.uuid = response.uuid;
    });
  }

  /**
   * Check if basid data is loading
   */
  public get isDataLoading() {
    return this.dataLoading;
  }

  /**
   * Handle download
   */
  public download() {
    if (!this.file) {
      return;
    }
    FileSaver.saveAs(this.file, this.filename);
  }

  /**
   * Get file as Blob
   */
  public get blob(): Blob | undefined {
    if (!this.file) {
      return undefined;
    }
    return this.file as Blob;
  }

  /**
   * Get file name
   */
  public get name(): string {
    return this.displayName || this.filename;
  }

  /**
   * Read file as dataUrl
   */
  public readFileAsdataUrl() {
    if (!this.blob) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        this.dataUrl = reader.result;
      }
    };

    reader.readAsDataURL(this.blob);
  }

  /**
   * Sync data from FileContract
   * @param data
   */
  public async syncData(data: FileContract) {
    this.id = data.id;
    this.mime = data.mime;
    this.filename = data.filename || 'undefined.pdf'; // TODO remove it when backend add filenames
    if (data.displayName) {
      this.displayName = data.displayName;
    } else if (this.filename) {
      this.displayName = this.filename;
    }
    this.url = data.url;
    this.uuid = data.uuid;
    this.filehash = data.filehash;

    data.filename = this.filename;

    // load converter for other data types like base64 or external url
    await this.loadConverterService(data);
  }

  /**
   * Sync data from File
   * @param file
   */
  public syncFile(file: File) {
    this.filename = file.name;
    this.displayName = file.name;
    this.mime = file.type;
    this.file = file;

    this.dataLoading = false;

    // initialize uploader service
    this.loadUploaderService();
  }

  /**
   * Load uploader service
   */
  private async loadUploaderService() {
    if (this.blob) {
      this.uploader = new FileUploadService(this.blob, this.filename);
    }
  }

  /**
   * Load proper service for other data type
   * @param data
   */
  private async loadConverterService(data: FileContract) {
    if (data.content) {
      this.converter = new Base64FileConverter(data);
    } else if (data.url) {
      this.converter = new ExternalUrlFileConverter(data);
    }
    if (this.converter) {
      // convert other data to File
      await this.converter.convertToFileType();

      if (!this.converter.error) {
        const file = this.converter.getFile();
        if (file) {
          this.syncFile(file);
        }
      } else {
        this.error = true;
      }
    }
  }
}
