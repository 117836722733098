import FileContract from '@/core/support/files/contracts/FileContract';
import FileConverterService from '@/core/support/files/FileConverterService';

export default class Base64FileConverter extends FileConverterService {
  /**
   * @info    Base64 file type converter
   *
   * @update  Lukas Laskowski 10.07.2019
   */

  /**
   * Data
   */
  public file!: File;

  private content!: string;
  private filename!: string;

  /**
   * Set model data
   * @param data
   */
  constructor(data: FileContract | null = null) {
    super();

    if (data === null) {
      return;
    }

    this.filename = data.filename || '';
    this.content = data.content || '';
  }

  /**
   * Convert data to File
   */
  public async convertToFileType() {
    this.file = new File([new Blob([this.content])], this.filename);
  }

  /**
   * Get File
   */
  public getFile(): File {
    return this.file;
  }
}
