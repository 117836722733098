import GlobalSettings from '@/shared/lib/settings/GlobalSettings';
import ApiHeaders from '@/core/api/headers/ApiHeaders';
import {ApiHeaderArrayContract} from '@/core/api/headers/contracts/ApiHeaderContract';
import ApiHeadersGroupEnum from '@/core/api/headers/support/ApiHeadersGroupEnum';

export default class FileUploadService {
  /**
   * @info    File upload service
   *
   * @update  Lukas Laskowski 10.07.2019
   */

  /**
   * Data
   */
  public filename!: string;
  public blob!: Blob | string;

  private uploadPercentage: number = 0;
  private uploadStatus!: string;
  private uuidloading: boolean = false;

  private readonly uploadUrl: string = `${GlobalSettings.apiURL}/v2/temp-files`;

  /**
   * Set model data
   * @param data
   * @param filename
   * @param headers
   */
  constructor(blob: Blob, filename: string) {
    this.blob = blob;
    this.filename = filename;
  }

  /**
   * Check if is uploaded
   */
  public get isUploaded() {
    return this.uploadStatus === 'done';
  }

  /**
   * Check if upload had errors
   */
  public get isUploadError() {
    return this.uploadStatus === 'error';
  }

  /**
   * Check if upload is in progress
   */
  public get isUploading() {
    return this.uploadStatus === 'pending';
  }

  /**
   * Check if getting file UUID is in progress
   */
  public get isUuidLoading() {
    return this.uuidloading;
  }

  /**
   * Get upload percentage
   */
  public get percentage() {
    return this.uploadPercentage || 0;
  }

  /**
   * Upload file
   */
  public async upload(): Promise<any> {
    if (!this.blob || !this.filename) {
      return;
    }
    // TODO change it to more flexible axios
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      // json response type
      req.overrideMimeType('application/json');
      req.responseType = 'json';

      // listeners
      req.upload.addEventListener('progress', (event: ProgressEvent) => {
        if (event.lengthComputable) {
          this.uploadPercentage = event.loaded / event.total * 100;
          this.uploadStatus = 'pending';
        }
      });
      req.upload.addEventListener('load', (event: Event) => {
        this.uploadPercentage = 100;
        this.uploadStatus = 'done';
        this.uuidloading = true;
      });
      req.upload.addEventListener('error', (event: any) => {
        this.uploadPercentage = 0;
        this.uploadStatus = 'error';
      });

      // create dom element
      const formData = new FormData();
      formData.append('file', this.blob, this.filename);
      // http request
      req.open('POST', this.uploadUrl, true);
      // api custom headers
      const headers: ApiHeaderArrayContract[] = ApiHeaders.getAsArray(ApiHeadersGroupEnum.FILES);
      headers.forEach((header: ApiHeaderArrayContract) => {
        req.setRequestHeader(header.name, header.value);
      });
      // request send
      req.send(formData);
      // handle response UUID
      req.addEventListener('load', (event: Event) => {
        this.uuidloading = false;
        resolve(req.response);
      });
    });
  }
}
